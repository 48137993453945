const config: WhiteLabelConfig = {
    "class-name": "math",
    "home-title": "Looking for a Maths Tutor?",
    "home-subtitle": "Search for a Specialist Maths Tutor",
    "primary-text-colour-class": "math-primary-text-colour",
    "secondary-text-colour-class": "math-secondary-text-colour",
    "primary-colour": "#1976d2",
    "secondary-colour": "#1976d2",//0262C2
    "primary-colour-mui": "#1976d2",//1976d2
    "secondary-colour-mui": "#000000",
    "chip-colour": "rgb(237, 108, 2)",
    "footer-content": "Maths Directory",
    "navbar-link": "Search maths tutors",
    "filter-level-label": "Maths level",
    "filter-level-values": {
        " ": "None",
        "7+/11+": "Maths ( 7+/11+ )",
        "Primary": "Maths ( Primary )",
        "Secondary": "Maths ( Secondary )",
        "GCSE": "Maths ( GCSE )",
        "A-level": "Maths ( A-level )",
        "Degree/Adult": "Maths ( Degree/Adult )"
    },
    "home-level-values": {
        " ": "None",
        "7+/11+": "Maths ( 7+/11+ )",
        "Primary": "Maths ( Primary )",
        "Secondary": "Maths ( Secondary )",
        "GCSE": "Maths ( GCSE )",
        "A-level": "Maths ( A-level )",
        "Degree/Adult": "Maths ( Degree/Adult )"
    },
    "profile-step-0-title": "What level of maths can you teach?",
    "search-result-title": "maths",
    "name-colour": "#0262C2",
    "message-colour": "#EBFAFD",
    "profile-step-3-title-placeholder": "E.g. Experienced and committed maths tutor. Limit: 50 characters",
    'profile-step-3-about-us-placeholder': "Tell us about yourself e.g. experience as a maths tutor, teaching style or approach. Limit: 190 words."
}

export default config;